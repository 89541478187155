body {
  background: #fff; text-align: center; color: #989898; font: 1.1em 'Roboto', Arial, Helvetica, sans-serif; line-height: 1.25; font-weight: 300;
  a {
    color: #000;
    text-decoration: underline;
  }

  img { display: block; line-height: 1; width: 100%; }

  .text { display: block; width: 100%; margin: 0 auto; font-size: 0.8em; }
  .text.strong { font-weight: 500; color: #555; }

  #wrap { width: 100%; max-width: 425px; margin: 0 auto; }
  #page { width: 100%; min-height: 600px; position: relative; }

  #content { position: relative; z-index: 69; background: #fff; padding-top: 12.5em; padding-bottom: 5em; }
  #content img { margin: 0 auto 3em auto; }

  @media screen and (min-width: 426px) {
    body { font-size: 14px; }
  }
}